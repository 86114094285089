import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerRow } from '@src/components/styled';
import PortfolioActions from '../../components/Actions';
import PortfolioHeadline from '../../components/Headline';
import ListContent from './ListConent';
import ApiConnectionFailureInline from '../components/ApiConnectionFailureInline';
const Container = styled(ContainerRow)((props) => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.m,
}));
const ActionsContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const PortfolioCardViewList = ({ portfolio, displayState, primaryActionType, onAction = () => { }, moreActionBtnOptions = undefined, showActionsBtn = true, }) => (_jsxs(Container, { children: [_jsx(PortfolioHeadline, { portfolio: portfolio }), displayState === 'default' && (_jsx(ListContent, { portfolio: portfolio })), _jsxs(ActionsContainer, { children: [displayState === 'error' && (_jsx(ApiConnectionFailureInline, { portfolio: portfolio, onAction: onAction })), showActionsBtn && (_jsx(PortfolioActions, { portfolio: portfolio, primaryActionType: primaryActionType, displayState: displayState, onAction: onAction, moreActionBtnOptions: moreActionBtnOptions }))] })] }));
export default PortfolioCardViewList;
