import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AlertTooltip from '@shared/ui/tooltips/AlertTooltip';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { BodyMedium, BodySmall } from '@components/styled/Typography';
import withMddBadge from '@shared/ui/badges/MddBadge/withMddBadge';
import { ContainerColumn, ContainerRow } from '@components/styled';
import PortfolioFollowersPreview from '../../../components/FollowersPreview';
import MonthlyReturnPositions from '../../../components/MonthlyReturnPositions';
const IncomeBadge = withPercentageIncomeBadge(BodyMedium);
const MddBadge = withMddBadge(BodyMedium);
const BadgeColumn = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const BadgeLabel = styled(BodySmall)(() => ({
    lineHeight: '15px',
}));
const MonthlyReturnRow = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const ListContent = ({ portfolio, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const userSubscribed = useMemo(() => !!portfolio.user_subscription_summary, [portfolio]);
    return (_jsxs(_Fragment, { children: [_jsxs(BadgeColumn, { children: [_jsx(BadgeLabel, { children: t('portfolio.past_month') }), _jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: portfolio.past_month_difference })] }), _jsxs(BadgeColumn, { children: [_jsx(BadgeLabel, { children: `${portfolio.whole_period_days_count}${t('portfolio.days_short')}.` }), _jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: portfolio.overall_difference })] }), _jsxs(BadgeColumn, { children: [_jsx(BadgeLabel, { children: t('portfolio.mdd.short') }), _jsx(MddBadge, { mdd: ((_a = portfolio.maxDrawdown) === null || _a === void 0 ? void 0 : _a.yearly) || 0, badgeVariant: 'tinted', badgeSize: 'large', badgeEndAdornment: (_jsx(AlertTooltip, { title: 'mdd', iconColor: 'currentColor', placement: 'top', arrow: true })) })] }), _jsxs(BadgeColumn, { children: [_jsx(BadgeLabel, { children: t('portfolio.avg_monthly_return') }), _jsxs(MonthlyReturnRow, { children: [_jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: userSubscribed ?
                                    ((_b = portfolio.user_subscription_summary) === null || _b === void 0 ? void 0 : _b.past_month_difference) || 0 :
                                    portfolio.followers.average_followers_monthly_return }), _jsx(MonthlyReturnPositions, { first: {
                                    position: 1,
                                    returnValue: portfolio.followers.average_followers_monthly_return_first,
                                }, last: portfolio.followers.count > 1 ? {
                                    position: portfolio.followers.count,
                                    returnValue: portfolio.followers.average_followers_monthly_return_last,
                                } : undefined })] })] }), _jsx(PortfolioFollowersPreview, { portfolio: portfolio })] }));
};
export default ListContent;
